Object.defineProperty(exports, "__esModule", {
  value: true,
});

// local
// export const baseUrl = "http://192.168.29.221:4000/web/"

// live
export const baseUrl = "https://www.secondstagebikini.com:4005/web/";

export const currencySym = "$";
export const getAllFilteredFeaturedProduct = "getAllFilteredFeaturedProduct";

export const signUpBuyer = "signUp";
export const loginBuyer = "login_buyer";
export const changePasswordBuyer = "changePassword_by_user";
export const forgotPassword = "forgotPassword";
export const myProfile_buyer = "myProfile";
export const editProfile_buyer = "editProfile";

export const product_details = "product_details";
export const delete_product_image = "delete_product_image";
export const edit_product = "edit_product";

export const addProduct = "add_product";
export const updateFeaturedProductVisibility =
  "updateFeaturedProductVisibility";
export const FeartuedPaymentAPI = "pay_now_for_featuredProduct";
export const updateToNoneOnCancel = "updateToNoneOnCancel";
export const getAllProduct = "get_all_Product";
export const getProductDetailsById = "getProductDetails_by_id";
export const fetch_categories = "fetch_categories";
export const filterAllProduct = "filterAllProduct";
export const deleteProduct = "deleteProduct";

export const addToCart = "add_cart";
export const getCartData = "getCartData";
export const upDateCartData = "upDateCartData";
export const deleteCartData = "deleteCartData/";
export const add_wishlist = "add_wishlist";
export const get_wishlist = "get_wishlist";

export const lenderissue = "lenderissue";
export const buyer_issue = "buyer_issue";
export const renter_issue = "renter_issue";

export const fetch_checkout = "fetch_checkout";
export const add_shipping_details = "add_shipping_details";
export const add_checkout = "add_checkout";
export const fetchOrder = "fetch_order";
export const order_list = "order_list";
export const sold_order_list = "sold_order_list";
export const fetch_shipping_details = "get_shipping_details";
export const get_my_product = "get_my_product";
export const getProductDetails_by_Category = "getProductDetails_by_Category/";
export const getProductDetails_by_Category_biknis =
  "getProductDetails_by_Category/Bikini";
export const getProductDetails_by_Category_figure =
  "getProductDetails_by_Category/Figure";
export const getProductDetails_by_Category_swimsuit =
  "getProductDetails_by_Category/Swimsuit";
export const getProductDetails_by_Category_fmg_wbff =
  "getProductDetails_by_Category/FMG_WBFF";
export const getProductDetails_by_Category_themewear =
  "getProductDetails_by_Category/Themewear";
export const getProductDetails_by_Category_accessories =
  "getProductDetails_by_Category/Accessories";

export const getAllBrand = "brand_count";
export const paynow = "paynow";
export const updatePaymentStatus = "update_payment_status";
export const contact_us = "contact_us";

export const checkBankDetails = "checkSellerbankdetail";
export const onboardSeller = "onboard-seller";
export const updateConnectedAccount = "update-connected-account";

// Email Section
export const allEmails = "get_all_emails";
export const allSentEmails = "get_all_sent_emails";
export const getEmailDetail = "emails/";
export const replyEmail = "reply";
export const getEmails = "unique-recipients";
export const forwardEmail = "forward";
export const allreceivedEmails = "emails/received/";

export const sendEmail = "send";
