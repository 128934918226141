import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { warnPages } from "../utils";

function Footer() {
  const [company, setCompany] = useState([
    { name: "About Us", path: "about" },
    { name: "FAQ", path: "faq" },
    { name: "Terms and Conditions", path: "terms" },
    { name: "Contact Us", path: "contact" },
  ]);
  const [shop, setShop] = useState([
    { name: "Bikini", path: "bikinis" },
    { name: "Figure", path: "figure" },
    { name: "Swimsuit", path: "swimsuit" },
    { name: "FMG/WBFF", path: "wbff" },
    { name: "Themewear", path: "themewear" },
    { name: "Accessories", path: "accessories" },
  ]);
  const [help, setHelp] = useState([
    { name: "My Account", path: "account-dashboard" },
    { name: "Log an Issue", path: "" },
    { name: "Contact", path: "contact" },
    { name: "Legal/Privacy", path: "privacy" },
  ]);
  const navigate = useNavigate();
  const [url, setUrl] = useState();
  const pathname = useLocation().pathname;
  const [isWarning, setIsWarning] = useState(false);

  // Navigate
  const handleNavigate = (val) => {
    if (warnPages?.includes(pathname)) {
      setIsWarning(true);
      console.log(pathname);
      setUrl(val);
    } else {
      if (val == "login") {
        localStorage.clear();
      }
      navigate("/" + val);
    }
  };
  return (
    <>
      <footer className="footer footer_type_1">
        <div className="footer-middle container">
          <div className="row row-cols-lg-5 row-cols-2">
            <div className="footer-column footer-store-info mb-4 mb-lg-0">
              <div className="logo">
                <a onClick={() => handleNavigate("")}>
                  <img
                    src="https://www.secondstagebikini.com:4005/logo.png"
                    alt="Second Stage"
                    className="logo__image d-block"
                  />
                </a>
              </div>
              <p className="m-0">
                <a href="mailto:secondstagebikini@mail.com" target="_top">
                  {" "}
                  <strong className="fw-medium">
                    secondstagebikini@mail.com
                  </strong>
                </a>
              </p>
              <ul class="social-links list-unstyled d-flex flex-wrap mb-0">
                <li>
                  <a
                    href="https://www.facebook.com/second.stage.bikini?mibextid=ZbWKwL"
                    target="_blank"
                    class="footer__social-link d-block"
                  >
                    <svg
                      class="svg-icon svg-icon_facebook"
                      width="9"
                      height="15"
                      viewBox="0 0 9 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_facebook" />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/secondstagebikini?igsh=dXJjam9pN2l0OTRk"
                    target="_blank"
                    class="footer__social-link d-block"
                  >
                    <svg
                      class="svg-icon svg-icon_instagram"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use href="#icon_instagram" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-column footer-menu mb-4 mb-lg-0">
              <h5 className="sub-menu__title text-uppercase">Company</h5>
              <ul className="sub-menu__list list-unstyled">
                {company.map((item, index) => {
                  return (
                    <li className="sub-menu__item" key={index}>
                      <a
                        className="menu-link menu-link_us-s"
                        onClick={() => handleNavigate(item?.path)}
                      >
                        {item.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer-column footer-menu mb-4 mb-lg-0">
              <h5 className="sub-menu__title text-uppercase">Shop</h5>
              <ul className="sub-menu__list list-unstyled">
                {shop.map((item, index) => {
                  return (
                    <li className="sub-menu__item" key={index}>
                      <a
                        className="menu-link menu-link_us-s"
                        onClick={() => handleNavigate(item?.path)}
                      >
                        {item.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer-column footer-menu mb-4 mb-lg-0">
              <h5 className="sub-menu__title text-uppercase">Help</h5>
              <ul className="sub-menu__list list-unstyled">
                {help.map((item, index) => {
                  return (
                    item?.name !== "Log an Issue" && (
                      <li className="sub-menu__item" key={index}>
                        <a
                          className="menu-link menu-link_us-s"
                          onClick={() => handleNavigate(item?.path)}
                        >
                          {item.name}
                        </a>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            <div className="footer-column footer-newsletter mb-4 mb-lg-0">
              <h5 className="sub-menu__title text-uppercase">
                News and Highlights
              </h5>
              <p>
                Be the first to get the latest news about trends, promotions,
                and much more!
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="footer-newsletter__form position-relative bg-body"
              >
                <input
                  className="form-control border-white"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                />
                <input
                  className="btn-link fw-medium bg-white position-absolute top-0 end-0 h-100"
                  type="button"
                  value="JOIN"
                />
              </form>
              <div className="mt-4 pt-3">
                <strong className="fw-medium">Secure payments</strong>
                <p className="mt-2">
                  <img
                    loading="lazy"
                    src="images/payment-options.png"
                    alt="Acceptable payment gateways"
                    className="mw-100"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom container">
          <div className="d-block d-md-flex align-items-center">
            <span className="footer-copyright mx-auto">
              ©{new Date().getFullYear()} Second Stage
            </span>
          </div>
        </div>
      </footer>

      {/* Warning modal S */}
      <div
        class={`modal fade ${isWarning ? "show" : ""}`}
        id="ct_warning_modal"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0 py-0">
              <button
                type="button"
                class="btn-close ct_warning_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsWarning(false)}
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body py-0">
              <div className="ct_warning_modal_cnt">
                <h4 className="ct_fs_24 text-center">
                  You're leaving this page and will lose progress, <br /> Are
                  you sure?
                </h4>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mt-4">
              <button
                type="button"
                class="btn btn-primary  text-uppercase"
                data-bs-dismiss="modal"
                onClick={() => setIsWarning(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary d-block text-uppercase ct_btn_yellow123"
                onClick={() => navigate("/" + url)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
