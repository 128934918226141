export const  warnPages = [
    "/register-seller",
    "/bank-details",
    "/new-message",
    "/message-preview",
    "/shop-checkout",
    "/lenderform",
    "/buyerform",
    "/renterform",
    "/register",
    "/contact"
  ];