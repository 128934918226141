import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { all } from "axios";
export const configJSON = require("../components/config");
function Cancel_order() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    const { token } = getValueFromUrl(location.pathname);
    getMyProfile(token);
    cancelOrder();
  }, []);
  const getMyProfile = (token) => {
    setIsLoader(true);
    axios({
      url: configJSON.baseUrl + configJSON.myProfile_buyer,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoader(false);
        if (res?.data?.success == true) {
          localStorage.setItem(
            "Profile",
            JSON.stringify(res?.data?.user_info[0])
          );
        }
      })
      .catch((error) => {
        setIsLoader(false);
      });
  };
  // Function to get the value from the URL
  const getValueFromUrl = (url) => {
    const parts = url.split("/");
    const cardId = atob(parts[parts.length - 2]);
    const token = parts[parts.length - 1];
    localStorage.setItem("token", JSON.stringify(token));
    console.log({ cardId, token });
    return { cardId, token };
  };

  const { cardId, token } = getValueFromUrl(location.pathname);
  const cancelOrder = () => {
    setIsLoader(true);
    const data = {
      cart_id: `${cardId}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.updatePaymentStatus,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  return (
    <>
      <Header />
      {isLoader && <div className="custom-loader"></div>}
      {!isLoader && (
        <main>
          <div class="mb-4 pb-4"></div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="ct_cancle_order_box">
                    <div className="ct_cancle_icon1">
                      <i class="fa-solid fa-xmark"></i>
                    </div>
                    <div className="ct_cancle_order_info">
                      <h3>Order Cancelled!</h3>
                      <p>Your order has been cancelled</p>

                      <div className="mt-4">
                        <a
                          className="btn btn-primary btn-checkout"
                          onClick={() => navigate("/")}
                        >
                          GO TO HOMEPAGE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="mb-4 pb-4"></div>
        </main>
      )}

      <Footer />
    </>
  );
}

export default Cancel_order;
