import moment from "moment"

export const dateFormate = (date) => {
  const now = moment();
  const givenDate = moment(date);

  const duration = moment.duration(now.diff(givenDate));
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const days = duration.asDays();
  const weeks = duration.asWeeks();

  if (minutes < 1) {
    return "just now";
  } else if (minutes < 60) {
    return Math.floor(minutes) + (Math.floor(minutes) === 1 ? " minute, ago" : " minutes, ago");
  } else if (hours < 24) {
    return Math.floor(hours) + (Math.floor(hours) === 1 ? " hour, ago" : " hours ago");
  } else if (days < 7) {
    return Math.floor(days) + (Math.floor(days) === 1 ? " day, ago" : " days, ago");
  } else {
    return Math.floor(weeks) + (Math.floor(weeks) === 1 ? " week, ago" : " weeks, ago");
  }
}

// export const extractName = (email) => {
//   const name = email.split('@')[0];
//   return name.charAt(0).toUpperCase() + name.slice(1);
// };

export const extractName = (email) => {
  if (!email || typeof email !== 'string') {
    console.log(('Invalid email address type',email))
    throw new Error('Invalid email address type',email);
  }

  const atIndex = email.indexOf('@');
  if (atIndex === -1) {
    throw new Error('Invalid email address');
  }

  const namePart = email.slice(0, atIndex);
  if (!namePart) {
    throw new Error('Email address has no username');
  }

  // Convert username to proper case (capitalize first letter, lowercase the rest)
  const name = namePart.charAt(0).toUpperCase() + namePart.slice(1).toLowerCase();

  return name;
};

export const filterItems = (items, searchValue, key) => {
  if (!searchValue) {
    return items;
  }
  return items.filter((item) => {
    const value = item[key]?.toLowerCase();
    return value?.includes(searchValue.toLowerCase());
  });
};

export const pipEmail = ()=>{
return JSON.parse(localStorage.getItem("Profile"))?.email
}

export const getClassHighlight = (isSubmit, val) => {
  return isSubmit ? (val ? "" : "highlight") : "";
};