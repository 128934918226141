import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import * as configJSON from "../components/config";

const ProductAddSuccess = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    updateStatus()
  }, []);

  const updateStatus = () => {
    const parts = pathname.split("/");
    const product_id = parts[parts.length - 1];
    setIsLoader(true);
    const data = {
      product_id: `${product_id}`,
    };
    axios({
      url: configJSON.baseUrl + configJSON.updateFeaturedProductVisibility,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setIsLoader(false);
      })
      .catch((err) => {
        setIsLoader(false);
      });
  };

  return (
    <>
      <>
        <Header />
        {isLoader && <div className="custom-loader"></div>}
        {!isLoader && (
          <main>
            <div class="mb-4 pb-4"></div>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <div className="ct_cancle_order_box">
                      <div className="ct_cancle_icon1">
                        <img src="../../images/success.png" />
                      </div>
                      <div className="ct_cancle_order_info">
                      <h3 className="mb-4">Product Added/Updated Successfully!</h3>
                        

                        <div className="mt-4">
                          <a
                            className="btn btn-primary btn-checkout"
                            onClick={() => navigate("/")}
                          >
                            GO TO HOMEPAGE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="mb-4 pb-4"></div>
          </main>
        )}

        <Footer />
      </>
    </>
  );
};

export default ProductAddSuccess;
