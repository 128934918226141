import * as Yup from "yup";

export const Schema_login_form = Yup.object({
  buyer_name: Yup.string().required("* name is required"),
  user_name: Yup.string().required("* user name is required"),
  Address: Yup.string().required("* address is required"),
  email: Yup.string()
    .email("enter valied email address")
    .required("* email is required"),
  phone_number: Yup.string()
    .matches(/^[1-9]\d*$/, "Phone number should be a positive number")
    .required("* Phone number is required"),
  // license_number: Yup.string().required("* license number is required"),
  password: Yup.string()
    .required("* password is required")
    .min(6, "password must be 6 digit"),
  Confirm_Password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  Agreement: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

export const Schema_login_form1 = Yup.object({
  userName: Yup.string().required("* Username/Email is required"),
  password: Yup.string()
    .required("* password is required")
    .min(6, "password must be 6 digit"),
});

export const Seller_Schema = Yup.object({
  buyer_name: Yup.string().required("* name is required"),
  user_name: Yup.string().required("* user name is required"),
  email: Yup.string()
    .email("enter valied email address")
    .required("* email is required"),
  phone_number: Yup.string().required("* phone no. is required"),
  license_number: Yup.string().optional(),
  agreement_deducted: Yup.boolean().oneOf(
    [true],
    "You must accept the conditions"
  ),
});

export const buyer_issue = Yup.object().shape({
  issue_claimed: Yup.string().required("Issue Claimed is required*"),
  listing_detail: Yup.string().required("Listing detail is required*"),
  product_id: Yup.string().required("Order number is required*"),
  date_due: Yup.date().required("Date due is required"),
  date_received: Yup.date().required("Date received is required"),
  amount_paid: Yup.string().required("Amount paid is required "),
  value_of_claim: Yup.number()
    .required("Value of Claim is required")
    .positive("Value of Claim must be a positive number"),
  file: Yup.mixed()
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      const validFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ]; // Example valid file types
      return validFileTypes.includes(value.type);
    })
    .required("Upload Product Photo is required"),
  add_note: Yup.string().required("Add a note is required"),
  // damaged_product: Yup.string().required('Damaged Product is required'),
  // cleaning_fee: Yup.number().required('Cleaning Fee is required').positive('Cleaning Fee must be a positive number'),
  // additional_not_listed: Yup.number().required('Additional not listed is required').positive('Additional not listed must be a positive number'),
  // tracking_number: Yup.string().required('Tracking Number is required'),
});
export const general_issue = Yup.object().shape({
  issue_claimed: Yup.string().required("Issue Claimed is required"),
  add_note: Yup.string().required("Add a note is required"),
  file: Yup.mixed()
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true;
      const validFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ]; // Example valid file types
      return validFileTypes.includes(value.type);
    })
    .required("Upload Product Photo is required"),
});

export const lender_issue = Yup.object().shape({
  issue_claimed: Yup.string().required("Issue Claimed is required"),
  listing_detail: Yup.string().required("Listing Detail is required"),
  product_id: Yup.string().required("Order number is required"),
  date_due: Yup.date().required("Date due is required"),
  date_received: Yup.date().required("Date Received is required"),
  amount_paid: Yup.string().required("Amount paid is required"),
  value_of_claim: Yup.number()
    .required("Value of Claim is required")
    .positive("Value of Claim must be a positive number"),
  file: Yup.mixed()
    .test("fileType", "Invalid file type", (value) => {
      if (!value) return true; // Allow if no file is selected
      const validFileTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ]; // Example valid file types
      return validFileTypes.includes(value.type);
    })
    .required("Upload Product Photo is required"),
  add_note: Yup.string().required("Description is required"),
  isCheck: Yup.boolean().oneOf([true], "Please accept the conditions"),
  // damage_fee: Yup.number().required('Damage Fee is required').positive('Damage Fee must be a positive number'),
  // total: Yup.number().required('Total is required').positive('Total must be a positive number'),
  // standard: Yup.number().required('Standard is required').positive('Standard must be a positive number'),
  // complex: Yup.number().required('Complex is required').positive('Complex must be a positive number'),
  // cleaning_fee: Yup.number().required('Cleaning Fee is required').positive('Cleaning Fee must be a positive number'),
  // additional_not_listed: Yup.number().required('Additional not listed is required').positive('Additional not listed must be a positive number'),
  // tracking_number: Yup.string().required('Tracking Number is required'),
});
