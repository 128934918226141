import React from 'react';
import { currencySym } from './config';

const ProductInfo = ({ label, value }) => {
  return (
    <div className="item" >
      <label className="h6">{label}</label>
      <span className='first_letter_uppercase'>{label == 'Product Name' ? value[0].toUpperCase() + value.slice(1) : value}</span>
    </div>
  );
};

export default ProductInfo;