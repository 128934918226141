import React from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Make sure you have the CSS file imported

const Images = ({ images }) => {
    return (
        <div style={{ maxWidth: '100%', margin: '0 auto', width:'99%' }}>
            <ImageGallery
                showPlayButton={false}
                disableSwipe= {false}
                showBullets={false}
                slideOnThumbnailOver={true}
                items={images} />
        </div>
    );
};

export default Images;
